var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-app-bar', {
    attrs: {
      "color": "primary",
      "flat": "",
      "height": "110"
    }
  }, [_c('v-spacer'), _c('a', {
    staticClass: "text-center",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "max-h-70px",
    attrs: {
      "src": "/media/logos/logo_light_155x30.png",
      "alt": ""
    }
  })]), _c('v-spacer')], 1), _c('v-content', {
    staticClass: "mb-12"
  }, [_c('v-container', {
    staticClass: "mb-12"
  }, [_c('v-container', {
    staticClass: "mb-12"
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_c('v-spacer'), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "font-size-h1 bold-text"
  }, [_vm._v(" Thank you for downloading the TestApp.io App ")])]), _c('v-spacer')], 1), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "bodyFont mb-4"
  }, [_vm._v(" Download not working ? "), _c('span', {
    staticClass: "primary--text bold-text cursor-pointer",
    on: {
      "click": _vm.downloadMobileApp
    }
  }, [_vm._v("Retry download.")])]), _c('div', {
    staticClass: "my-4"
  }, [_c('v-divider')], 1), _c('div', {
    staticClass: "text-center bold-text font-size-h3"
  }, [_vm._v(" Steps to install ")]), _c('div', {
    staticClass: "my-4"
  }, [_c('v-divider')], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-left font-size-h3 bold-text",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" 1. Turn on unknown source ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.images[0]
    }
  })], 1)], 1), _c('div', {
    staticClass: "my-4"
  }, [_c('v-divider')], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-left font-size-h3 bold-text",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" 2. Install App ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.images[0]
    }
  })], 1)], 1), _c('div', {
    staticClass: "my-4"
  }, [_c('v-divider')], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-left font-size-h3 bold-text",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" 3. Open App ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.images[0]
    }
  })], 1)], 1), _c('div', {
    staticClass: "my-4"
  }, [_c('v-divider')], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-footer', [_c('k-t-footer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }